<template>
  <div id="app">
    <template v-if="display == 'splash'">
      <header>
        <h1 class="title">MatchCards</h1>
      </header>
      <main>
        <p class="intro">MatchCards is a card matching game created with HTML5, CSS3 and JavaScript by <a href="https://logic-wire.de/">logic-wire&nbsp;websolutions</a>.</p>
        <p><a href="#" @click="start()">start game</a></p>
      </main>
    </template>
    <template v-else>
      <Board />
    </template>
  </div>
</template>

<script>
import Board from './components/Board.vue'

export default {
  name: 'MatchCards',
  data () {
    return {
        display: 'splash' 
    };
  },
  components: {
    Board
  },
  methods: {
    start() {
      this.display = 'board';
    }
  }
}
</script>

<style>
* {
  line-height: 1.5em;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  font-family: Andale Mono,AndaleMono,monospace;   
  background: #111;
  color: #ccc;
  margin: 0;
  padding: 0;
  text-align: center;
  overflow-y: auto;
}

a {
  color: #ccc;
}

a:hover {
  color: #5b99c3;
  text-decoration: none;	
}

header {
  width: 100%;
  
}

footer {
  width: 100%;
  flex: 1;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}

.title {
  margin:0;
  padding: 1em;
}

.intro {
  margin-bottom: 2em;
}
</style>