<template>
  <div>
    <main class="game-main">
      <div class="board">
        <div v-for="(card, index) in cards" :key="index" class="card" :class="isRevealed(index)" :id="'card'+index" :data-id="card.id" @click="revealCard(index)">
          <div class="card-back"></div>
          <img class="card-image" :src="'img/animals/'+card.id+'.jpg'" alt="">
        </div>
      </div>
      <p>moves: {{ moves }} &#183; record: {{ record }}</p>
    </main>
    <footer class="game-footer">
      <p><a href="#" @click="restart()">restart</a></p>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'Board',
  data () {
    return {
        card1: null,
        card2: null,
        record: 0,
        moves: 0,
        cardsRevealed: 0,
        cardsSolved: 0,
        showCardsFor: 2500,
        images: [
          'bird',
          'cat',
          'dog',
          'fish',
          'frog',
          'goat',
          'horse',
          'mouse'
        ],
        cards: [],
    };
  },
  mounted () {
    this.createCards();
  },
  methods: {
    createCards() {
      const images = [...this.images, ...this.images];
      
      const cards = images.map(image => {
        return {
          id: image, 
          revealed: false,
          solved: false
        };
      });
      
      for (let i = cards.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [cards[i], cards[j]] = [cards[j], cards[i]];
      }
      
      this.cards = cards;
    },
    revealCard(index) {
      const card = this.cards[index];
      
      if (this.cardsRevealed == 2) {
        //this.hideCards();
        //need to clear timeout in order to prevent running hideCards() twice
      } else if (!card.revealed && !card.solved && this.cardsRevealed < 2) {
        card.revealed = true;
        
        this.cardsRevealed++;
        
        if (this.card1) {
          this.card2 = card;
        } else {
          this.card1 = card;
        }
        
        if (this.cardsRevealed == 2) {
          this.moves++;
          
          if (this.card1.id == this.card2.id) {
            this.card1.solved = true;
            this.card2.solved = true;
            this.cardsSolved = this.cardsSolved + 2;
            this.clearCards();
            
            if (this.cardsSolved == this.cards.length) {
              if (this.record == 0 || this.moves < this.record) {
                this.record = this.moves;
              }
            }
          } else {
            setTimeout(()=>{this.hideCards()}, this.showCardsFor);
          }
        }
      }
    },
    hideCards() {
      this.card1.revealed = false;
      this.card2.revealed = false;
      this.clearCards();
    },
    clearCards() {
      this.card1 = null;
      this.card2 = null;
      this.cardsRevealed = 0;
    },
    isRevealed(index) {
      return (this.cards[index].revealed ? 'card-revealed' : '');
    },
    restart() {
      this.moves = 0;
      this.cardsSolved = 0;
      this.clearCards();
      this.createCards();
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.game-main {
  flex: 1;
}

.game-footer {
  flex: none;
}

.board {
  flex: 1;
  display: grid;
  grid-template: auto auto auto auto / auto auto auto auto;
  /*grid-template: 40vw 40vw / 40vw 40vw;*/
  padding: 0.5rem;
  gap: 0.5rem 0.5rem;
  align-items: center;
  align-content: center;
  margin: 0 auto;
  max-width: 81vh;
}

.card {
  display: grid-item;
  position: relative;
}

.card-back {
  border: 1px solid #000;
  border-radius: 0.25em;
  box-shadow: 2px 2px 2px #000;
  position: absolute;
  background: #333;
  background: rgb(0,0,0);
  background: linear-gradient(145deg, rgba(51,51,51,1) 0%, rgba(40,40,40,1) 50%, rgba(51,51,51,1) 100%); 
  width: 100%;
  height: 100%;
  transform: rotateX(0);
  transition: transform .5s ease;
  top: 0;
  left: 0;
}

.card-image {
  border: 1px solid #000;
  border-radius: 0.25em;
  box-shadow: 3px 3px 2px #000;
  backface-visibility: hidden;
  transform: rotateX(180deg);
  transition: transform .5s ease;
  top: 0;
  left: 0;
}

.card:hover {
  cursor: pointer;
}

.card-revealed .card-back,
.card-solved .card-back {
  transform: rotateX(180deg);
  backface-visibility: hidden;
}

.card-revealed .card-image,
.card-solved .card-image {
  transform: rotateX(0);
}
</style>
